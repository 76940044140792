/* @import must be at top of file, otherwise CSS will not work */

@font-face {
    font-family: "TenorSans-Regular";
    src: url("../fonts/TenorSans-Regular.ttf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: 'AvenirLTPro-Medium';
    font-weight: 500;
    src: url('../fonts/3A0B99_0_0.eot');
    src: url('../fonts/3A0B99_0_0.eot?#iefix') format('embedded-opentype'), url('../fonts/3A0B99_0_0.woff2') format('woff2'), url('../fonts/3A0B99_0_0.woff') format('woff'), url('../fonts/3A0B99_0_0.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'AvenirLTPro-Black';
    src: url('../fonts/3A0B99_1_0.eot');
    src: url('../fonts/3A0B99_1_0.eot?#iefix') format('embedded-opentype'), url('../fonts/3A0B99_1_0.woff2') format('woff2'), url('../fonts/3A0B99_1_0.woff') format('woff'), url('../fonts/3A0B99_1_0.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: "Noe-BoldItalic";
    font-style: normal;
    src: url('../fonts/NoeText-BoldItalic.eot');
    src: url('../fonts/NoeText-BoldItalic.eot?#iefix') format('embedded-opentype'), url('../fonts/NoeText-BoldItalic.woff2') format('woff2'), url('../fonts/NoeText-BoldItalic.woff') format('woff');
    font-display: swap;
}

@font-face {
    font-family: "Noe-DisplayBold";
    src: url("../fonts/Noe-Display-Bold.otf") format("opentype");
    font-display: swap;
}

@font-face {
    font-family: "Noe-Display-RegularItalic";
    src: url("../fonts/Noe-Display-RegularItalic.otf") format("opentype");
    font-display: swap;
}

@font-face {
    font-family: "Noe-Display-Medium";
    src: url("../fonts/Noe-Display-Medium.otf") format("opentype");
    font-display: swap;
}

@font-face {
    font-family: "AvenirNextLTPro-Regular";
    src: url("../fonts/AvenirNextLTPro-Regular.otf") format("opentype");
}

@font-face {
    font-family: "AvenirNextLTPro-Bold";
    src: url("../fonts/AvenirNextLTPro-Bold.otf") format("opentype");
}

@font-face {
    font-family: "AvenirNextLTPro-Demi";
    src: url("../fonts/AvenirNextLTPro-Demi.otf") format("opentype");
}

@font-face {
    font-family: "AvenirNextLTPro-Medium";
    src: url("../fonts/AvenirNextLTPro-Medium.otf") format("opentype");
}

@font-face {
    font-family: "Inter-Regular";
    src: url("../fonts/Inter-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Inter-Medium";
    src: url("../fonts/Inter-Medium.ttf") format("truetype");
}

@font-face {
    font-family: "Inter-Bold";
    src: url("../fonts/Inter-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "DomaineText-Regular";
    src: url("../fonts/DomaineText-Regular.otf") format("opentype");
}
